<template>
  <div class="layout default-layout">
    <div class="layout-content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultLayout',
};
</script>
